<template>
  <Dialog v-model="show" title="Alert" :forceOption="forceOption" nocancel>
    <p v-if="!isArray(msg)">
      {{ msg }}
    </p>
    <ul v-else>
      <li v-for="m in msg" :key="m">
        {{ m }}
      </li>
    </ul>
  </Dialog>
</template>

<script lang="ts">
import Dialog from './Dialog.vue';
import { showAlertDialog, dialogMessage, forceOption } from '../utils';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AlertDialog',
  components: { Dialog },
  setup() {
    const show = showAlertDialog;
    const msg = dialogMessage;

    return {
      show,
      msg,
      forceOption,
      isArray: Array.isArray,
    };
  },
});
</script>
